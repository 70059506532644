.container-LP {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    
}

.sidebar {
    width:fit-content;
    height:100%;
    
}

.bodyContainer-LP {
    width:80%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    margin-bottom: 3rem;
    
    
}

.display-tasks {
    width:fit-content;
    height: 30%;
    overflow-x: scroll;
}

.display-tasks::-webkit-scrollbar {
    display: none;
}

.display-score {
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap:2rem;
    padding:1rem;
}

.score {
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
}

.score-number {

    font-family: "Skranji", system-ui;
    font-weight: 700;
    font-style: normal;
    font-size: 20rem;

}

.score-header {
    font-size:3rem;
    font-family: "Bowlby One", sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-score {
    
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    background-color: #3457D5;
    font-size: 1.8rem;
    border-radius: 50%;
    color: white;
    
}

@media (max-width: 1070px) {
    .score-number {
        font-size:15rem;
    }
    
}

@media (max-height: 800px) {
    .score-number {
        font-size:15rem;
    }
    
}

@media (max-width: 915px) {
    .score-number {
        font-size:10rem;
    }
    
}

@media (max-width: 600px) {
    .score-number {
        font-size:7rem;
    }

    .btn-score {
        width:2rem;
        height:2rem;
        border-radius: 50%;
        font-size:1rem;
    }
    
}
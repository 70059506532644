.anly-container {
    display:flex;
    flex-direction: row;
    width:100vw;
    height: 100vh;
    background-color: #F8F8F8;
    
}

.anly-container::-webkit-scrollbar {
    display: none;
}


.anly-body {
    display: flex;
    flex-direction: column;
    padding:1rem;   
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    flex:1;
}

.anly-body::-webkit-scrollbar {
    display: none;
}

.anly-header {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.anly-header-name {
    font-size: 2rem;
    padding:1rem;
    font-family: "Anton", sans-serif;
    font-style: normal;
}

.av-container {
    display: flex;
    flex-direction: row;
    gap:0.5rem;
    flex-wrap: wrap;
}

.av {
    padding:2rem;
    border-radius: 0.5rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-width:15rem;
    font-size: 1.3rem;
}

.daily-av {
    border-left: 3px solid #00CED1;
}

.daily-av-label {
    color:#00CED1;
    font-size:1.1rem;
}

.weekly-av {
    border-left: 3px solid #66FF00;
}

.weekly-av-label {
    color: #66FF00;
    font-size:1.1rem;
}

.monthly-av {
    border-left:3px solid #FFC72C;
}

.monthly-av-label {
    color: #FFC72C;
    font-size:1.1rem;
}

.yearly-av {
    border-left:3px solid #662d91;
}

.yearly-av-label {
    color:#662d91;
    font-size:1.1rem;
}

.dal {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
}

.first-two {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:0.5rem;
}

.second-two {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap:0.5rem;
}

.charts-row1 {
    width:100%;
    padding:1rem;
    display: flex;
    flex-direction: row;
    gap:1rem;
    flex-wrap: wrap;
   
}

.bar-chart {
    width:30rem;
    height:20rem ;
    
}

.bar-chart2 {
    width:30rem;
    height:20rem ;
    
    
}

.chart-row2 {
    padding:1rem;
    
}

.progress-bar {
    width:70%;
    gap:1rem;
}

@media (max-width:890px) {
    .av {
        max-width:12rem;
    }

    .dal {
        font-size:0.8rem;
    }

    .bar-chart {
        width:25rem;
        height:15rem ;
        
    }
    
    .bar-chart2 {
        width:25rem;
        height:15rem ;
        
        
    }
    
}

@media (max-width:1300px) {
    .progress-bar {
        width:100%;
        
    }
}

@media (max-width:550px) {
    .bar-chart {
        width:20rem;
        height:12rem ;
        
    }
    
    .bar-chart2 {
        width:20rem;
        height:12rem ;
        
        
    }
}

@media (max-width:420px) {
    .bar-chart {
        width:15rem;
        height:12rem ;
        
    }
    
    .bar-chart2 {
        width:15rem;
        height:12rem ;
        
        
    }
}
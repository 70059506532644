.tasks-container {
    width:100%;
    
}

.task-update {
    display: flex;
    flex-direction: column;
    width: fit-content;
    height:fit-content;
    padding: 1rem;
    border-radius: 0.7rem;
    border-bottom: solid grey 0.1rem;
    border-color: grey;
    width:100%;
    gap:1rem;
    flex-wrap: wrap;
    background-color: rgb(255, 165, 0,0.5);
}

.task-update:hover {
    background-color: rgb(255, 165, 0);
}

.task-delete {
    display: flex;
    flex-direction: column;
    width: fit-content;
    height:fit-content;
    padding: 1rem;
    border-radius: 0.7rem;
    border-bottom: solid grey 0.1rem;
    border-color: grey;
    width:100%;
    gap:1rem;
    flex-wrap: wrap;
    background-color: rgb(255, 55, 0,0.5);
}

.task-delete:hover {
    
    background-color: rgb(255, 0, 0);
}



.top {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap:1rem;
    
}

.name {
    font-family: "Bowlby One", sans-serif;
    font-weight: 50;
    font-size: 2rem;
}

.description {
    font-family: "Montserrat", sans-serif;
    display: flex;
    flex-wrap: wrap;
    max-width: 50%;
    font-size: 0.8rem;
}

.update-button {
    width:3rem;
    height:1rem;
}

.selection {
    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding:1rem;
    gap:1rem;
}

@media (max-width: 500px) {
    .name {
        font-family: "Bowlby One", sans-serif;
        font-weight: 50;
        font-size: 1rem;
    }
    
}